.smartphone {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 90%;
  max-width: 600px;
}
@media only screen and (max-width: 500px) {
  .smartphone {
    top: 75%;
  }
}
@media only screen and (max-width: 400px) {
  .smartphone {
    top: 80%;
  }
}

.smartphone img {
  width: 90%;
  max-width: 600px;
}

/* .screen {
  position: absolute;
  top: 11px;
  left: 80px;
  width: calc(100% - 160px);
} */
