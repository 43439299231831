.videoBlock {
  position: relative;
  text-align: left;
  padding: 0;
  margin: 50px 0 0 0;
}

.videoBlock > * {
  position: relative;
  z-index: 2;
}

.videoHeadingsDesktop {
  display: none;
}

.videoHeadingsMobile {
  display: block;
}

@media only screen and (min-width: 992px) {
  .videoBlock {
    margin: 70px 0 0 0;
  }

  .videoColumn {
    padding-right: 30px;
  }

  .textColumn {
    padding-left: 30px;
  }

  .alt .videoColumn {
    order: 2;
    padding-right: 0;
    padding-left: 30px;
  }

  .alt .textColumn {
    order: 1;
    padding-left: 0;
    padding-right: 30px;
    text-align: right;
  }

  .videoHeadingsDesktop {
    display: block;
  }

  .videoHeadingsMobile {
    display: none;
  }

  .flexContainer {
    display: flex;
    position: relative;
  }

  .flexContainer > .flexColumn {
    flex: 1;
  }
}

@media only screen and (min-width: 1200px) {
  .videoColumn {
    padding-right: 60px;
  }

  .textColumn {
    padding-left: 60px;
  }

  .alt .videoColumn {
    padding-right: 0;
    padding-left: 60px;
  }

  .alt .textColumn {
    padding-left: 0;
    padding-right: 60px;
  }
}
