.section {
  margin: 100px 20%;
}
@media only screen and (max-width: 700px) {
  .section {
    margin: 100px 20px;
  }
}
@media only screen and (max-width: 530px) {
  .section {
    margin: 50px 20px;
  }
}
