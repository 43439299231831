.Loader {
  position: relative;
  margin: 60px auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top: 5px solid lighten(#000, 90%);
  border-right: 5px solid lighten(#000, 90%);
  border-bottom: 5px solid lighten(#000, 90%);
  border-left: 5px solid #000;
  transform: translateZ(0);
  animation: rotate 0.8s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
