.Header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 2% 2%;
  z-index: 500;
  display: flex;
  flex: 1;
}
@media only screen and (max-width: 760px) {
  .Header[open] {
    position: fixed;
  }
}

.logo {
  order: 1;
  z-index: 20;
}
.logo img {
  width: 170px;
  height: 43px;
}

.desktop {
  display: flex;
  flex: 1;
  order: 2;
}
@media only screen and (max-width: 760px) {
  .desktop {
    display: none;
  }
}

.menu {
  flex: 1;
  order: 1;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: right;
}

.menu ul li {
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  margin: 15px 20px;
  font-size: 11px;
}

.menu ul li a {
  display: block;
  color: #fff;
  text-decoration: none;
}

.social {
  order: 2;
}

.social ul {
  margin: 0 0 0 20px;
  padding: 0;
  list-style-type: none;
  text-align: right;
}

.social ul li {
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  margin: 13px 5px;
  font-size: 1vw;
}

.social ul li a {
  display: inline-block;
}

.social ul li a img {
  border: 0;
  width: 20px;
  height: 20px;
}

.menuButton {
  display: none;
  position: absolute;
  top: 22px;
  right: 20px;
  z-index: 20;
}
@media only screen and (max-width: 760px) {
  .menuButton {
    display: block;
  }
}

.menuButton label div {
  height: 2px;
  width: 25px;
  margin-bottom: 5px;
  background-color: #fff;
}
.menuButton label div:last-child {
  margin-bottom: 0;
}

.mobileMenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(110%);
  height: 100%;
  width: 100%;
  padding: 80px 20px 20px 20px;
  background-color: rgba(78, 195, 237, 0.97);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
    opacity 0.1s linear;
  z-index: 10;
  opacity: 0;
}
@media only screen and (max-width: 760px) {
  .mobileMenu {
    display: block;
  }
  .mobileMenu[open] {
    transform: translateX(0);
    opacity: 1;
  }
}
.mobileMenu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.mobileMenu ul li {
  text-align: center;
}
.mobileMenu ul li a {
  display: block;
  padding: 20px;
  color: #fff;
  text-decoration: none;
}
.mobileMenu ul.socialList {
  max-width: 210px;
  margin: 20px auto;
}
.mobileMenu ul.socialList li {
  display: inline-block;
}
