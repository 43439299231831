.h1,
.h2,
.h3 {
  font-weight: 500;
  margin: 0 0 20px;
  padding: 0;
  line-height: 1.2em;
}
.h1 {
  font-size: 40px;
  color: #351e56;
}
.h2 {
  font-size: 40px;
  color: #351e56;
}
.h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #5e38b2;
  text-transform: uppercase;
}

.p {
  padding: 0;
  margin: 0 0 1em;
  line-height: 1.4em;
}
