.VideoBox {
  overflow: hidden;
  width: 100%;
  margin: 20px 0;
}

.VideoBox > div {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.VideoBox > div > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 992px) {
  .VideoBox {
    margin: 0;
  }
}
