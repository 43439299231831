* {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  color: #595959;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 16px; /* ~16px; */
  line-height: 1.45;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
