.contentBlock {
  text-align: center;
  margin: 0 auto;
}

.hasMargin h2 {
  font-weight: bold;
}

@media only screen and (min-width: 992px) {
  .hasMargin {
    margin: 50px 20% 100px 20%;
  }
}
