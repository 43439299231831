.Hero {
  position: relative;
  min-height: 300px;
  text-align: center;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.background::before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  content: "";
  background: linear-gradient(
    to right,
    rgba(73, 199, 238, 1) 0%,
    rgba(147, 33, 255, 0.65) 100%
  );
}
.background .image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
@media only screen and (max-width: 1200px) {
  .background .image {
    width: auto;
    height: 100%;
  }
}
.background .mask {
  position: absolute;
  bottom: -70px;
  width: 100.8%;
  left: -0.4%;
  right: 0;
  z-index: 1;
}
@media only screen and (max-width: 700px) {
  .background .mask {
    bottom: -3px;
  }
}

.content {
  position: relative;
  padding: 150px 0 19% 0;
  margin: 0 auto;
  width: 700px;
  max-width: 90%;
  z-index: 1;
}
@media only screen and (max-width: 1000px) {
  .content {
    padding-bottom: 200px;
  }
}
@media only screen and (max-width: 700px) {
  .content {
    padding-top: 100px;
  }
}
.content h1 {
  color: #fff;
  font-size: 3vw;
  margin-bottom: 25px;
}
@media only screen and (max-width: 900px) {
  .content h1 {
    font-size: 30px;
  }
}
.content p {
  color: #fff;
  font-size: 1.3vw;
  margin-bottom: 25px;
}
@media only screen and (max-width: 900px) {
  .content p {
    font-size: 13px;
  }
}
.content a {
  margin: 10px 30px;
}
@media only screen and (max-width: 700px) {
  .content a {
    margin: 20px 10px 0 0;
  }
}

.mouseIcon {
  margin-top: 20px;
  text-align: center;
}
@media only screen and (max-width: 700px) {
  .mouseIcon {
    display: none;
  }
}
.mouseIcon img {
  width: 15px;
}
