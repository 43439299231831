.footer {
  background: #1e1e1e;
  padding: 50px 80px;
  box-sizing: border-box;
  font-size: 10px;
}
@media only screen and (max-width: 700px) {
  .footer {
    padding: 20px;
    text-align: center;
  }
}

.row {
  display: flex;
  flex: 1;
  margin-bottom: 40px;
}
@media only screen and (max-width: 700px) {
  .row {
    display: block;
  }
}

.voyagerLogo {
  width: 20%;
  height: 50px;
  order: 1;
}
@media only screen and (max-width: 700px) {
  .voyagerLogo {
    width: 200px;
  }
}

.links {
  height: 50px;
  flex: 1;
  order: 2;
}
@media only screen and (max-width: 700px) {
  .links {
    width: 100%;
  }
}
.links ul {
  margin: 19px 0 0 60px;
  padding: 0;
  list-style-type: none;
}
@media only screen and (max-width: 700px) {
  .links ul {
    margin: 50px 0;
  }
}
.links ul li {
  display: inline-block;
  border-right: 1px solid #595959;
  padding: 0 20px;
  font-size: 10px;
}
.links ul li:first-child {
  padding-left: 0;
}
.links ul li:last-child {
  border-right: 0;
}
.links ul li a {
  text-transform: uppercase;
  text-decoration: none;
  color: #595959;
}

.coopLogos {
  width: 20%;
  order: 3;
}
@media only screen and (max-width: 700px) {
  .coopLogos {
    width: 100%;
  }
}
.coopLogos p {
  margin: 18px 0 10px 0;
  text-transform: uppercase;
}
.coopLogos img {
  max-width: 100%;
}
