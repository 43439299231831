.Button,
.Button:link {
  display: inline-block;
  padding: 0.9em 2em 0.85em;
  background: #49c7ef;
  border-radius: 2em;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
}
.Button:active,
.Button:hover {
  background: #12a6d3;
}
