.particleSection {
  position: relative;
  text-align: left;
  background: #f2f4fd;
  padding: 3em 20px;
  margin: 100px 0;
}

.particleSection > * {
  position: relative;
  z-index: 2;
}

.particle {
  position: absolute;
  display: block;
  z-index: 1;
  pointer-events: none;
}

.particle1 {
  position: absolute;
  display: block;
  z-index: 1;
  pointer-events: none;
  max-width: 70%;
  top: 400px;
  left: -50px;
  transform: rotate(-65deg);
}

.particle2 {
  position: absolute;
  display: block;
  z-index: 1;
  pointer-events: none;
  height: 70px;
  top: -30px;
  right: 10%;
  transform: rotate(11deg);
}

.particle3 {
  position: absolute;
  display: block;
  z-index: 1;
  pointer-events: none;
  height: 50px;
  bottom: -20px;
  left: 50px;
  transform: rotate(-150deg);
}

@media only screen and (min-width: 992px) {
  .particleSection {
    padding: 3em;
  }

  .particle1 {
    max-width: 40%;
    top: 250px;
  }

  .particle2 {
    top: 150px;
  }
}
